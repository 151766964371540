<template lang="">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 계정관리 / 계정 상세정보</h2>
    </div>

    <div class="form-box mt-sm">
      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">아이디<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="ID" class="element-input size-lg" v-model="managerID" readonly="readonly">
          </div>
        </div>
        
      </div>

      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">이름<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="이름" v-model="managerName" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerName==''">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">휴대폰<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="휴대폰" v-model="managerPhone" @keyup="validatePhone()" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerPhone==''">필수 입력 사항 입니다.</p>
        </div>
        <div class="form-item row-type">
          <label class="form-title">이메일<span class="requisite">*</span></label>
          <div class="form-element">
            <input type="text" placeholder="이메일" v-model="managerEmail" @keyup="validateEmail()" class="element-input size-lg">
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerEmail==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerEmail!='' && !isValidataEmail">이메일 형식을 확인해 주세요.</p>
        </div>
      </div>

      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">권한유형<span class="requisite">*</span></label>
          <div class="form-element">
            
            <t-rich-select
              :options="roleList"
              class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
              textAttribute="value"
              valueAttribute="id"
              :hideSearchBox="false"
              v-model="managerRole"
              :fixedClasses="richSelectXL"
            >
            </t-rich-select>
          </div>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="managerRole==''">필수 입력 사항 입니다.</p>
        </div>
      </div>

      <div class="form-group-box">
          <div class="form-item row-type size-wide">
              <label class="form-title">관리자 메모</label>
              <div class="form-element size-b">
                  <textarea name="" class="element-textarea" v-model="managerDescription" placeholder="메모"></textarea>
              </div>
          </div>
      </div>
    </div>

    <div class="title-area mt-40">
      <p class="title-c">단말기 알림 SMS</p>
      <div class="element-switch">
        <input class="checkbox-input" type="checkbox" id="smaple51-1" v-model="isAlert" />
        <label class="checkbox-label" for="smaple51-1"><span class="for-a11y">Toggle Checkbox</span></label>
      </div>
    </div>

    <div class="choice-group mt-s">
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox1" v-model="alertDetect" />
        <label class="checkbox-label" for="checkbox1">신규 불법 카메라 탐지</label>
      </div>
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox2" v-model="alertOffline" />
        <label class="checkbox-label" for="checkbox2">단말기 오프라인</label>
      </div>
      <div class="element-checkbox checkbox-text">
        <input class="radio-input" type="checkbox" id="checkbox3" v-model="alertMemo" />
        <label class="checkbox-label" for="checkbox3">위치 정보에 단말메모 사용</label>
      </div>
    </div>

    <div class="note-group">
      <div class="note-item">
        <p class="note-title">불법 카메라 탐지 알림 문구</p>
        <div class="note-content">
          <span class="note-value">{관리구역명}</span>
          <span class="note-value">{상세위치}</span>
          <span class="note-value" v-if="alertMemo">{단말메모}</span>
          <span class="note-value">{단말기명}</span>
          <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textDetect" placeholder="에서 신규 불법 카메라가 탐지되었습니다. 확인 바랍니다." />
        </div>
      </div>
      <div class="note-item">
        <p class="note-title">단말기 오프라인 알림 문구</p>
        <div class="note-content">
          <span class="note-value">{관리구역명}</span>
          <span class="note-value">{상세위치}</span>
          <span class="note-value" v-if="alertMemo">{단말메모}</span>
          <span class="note-value">{단말기명}</span>
          <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textOffline" placeholder="가 오프라인 상태입니다. 확인 바랍니다." />
        </div>
      </div>
    </div>

    <div class="page-btn-area">
      <button type="button" class="element-btn bg-red size-lg" @click="cancelUpdate()">취소</button>
      <!-- <button type="button" class="element-btn bg-blue size-lg" @click="managerUpdate()" :disabled="checkUpdateDisable()">저장</button> -->
      <button type="button" class="element-btn bg-blue size-lg" @click="managerUpdate()">저장</button>
    </div>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"
import { richSelectXL } from "@/references/fixedClass"

import { encryptString, decryptString } from '@/utils/config'

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
    this.getManagerInfo();
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCurrentUser","getCompanyGuid"]),
  },
  data () {
    return {
      companyGuid: '',   //테스트로 일단 하드코딩 합니다.
      
      richSelectXL,
      roleList: [
        {id:'member',value:'운영자'},
        {id:'agent',value:'현장관리자'},
      ],
      managerID: '',
      managerName: '',
      managerPhone: '',
      managerEmail: '',
      managerRole: 'agent',
      managerDescription: '',
      managerGuid: '',
      
      isAlert: true,
      alertDetect: false,
      alertOffline: false,
      alertMemo: false,
      textDetect: '',
      textOffline: '',
      isValidataEmail: false,

      isCheckManagerID: false,
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    async getManagerInfo () {
      let reqObj = {}
      reqObj.target = `/manager/info/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData == null) {
          this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})

          this.$router.push({name:'CompanyList'})
        }
        else {
          this.managerID = decryptString(result.msg.retData.managerID)
          this.managerName = decryptString(result.msg.retData.managerName)
          this.managerPhone = decryptString(result.msg.retData.managerPhone)
          this.managerEmail = decryptString(result.msg.retData.managerEmail)
          this.managerRole = result.msg.retData.managerRole
          this.managerDescription = result.msg.retData.managerDescription

          this.isAlert = result.msg.retData.isUseSMS === 'yes' ? true : false
          this.alertDetect = result.msg.retData.isDetectAlarm === 'yes' ? true : false
          this.alertOffline = result.msg.retData.isOffLineAlarm === 'yes' ? true : false
          this.alertMemo = result.msg.retData.isMemoAlarm === 'yes' ? true : false

          this.textDetect = result.msg.retData.detectWords
          this.textOffline = result.msg.retData.offLineWords

          this.managerGuid = result.msg.retData.managerGuid
          this.validateEmail()
        }
      }
      else {
        this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})

        this.$router.push({name:'CompanyList'})
      }
    },
    
    cancelUpdate () {
      this.$router.push({name:'ManagerList'})
    },
    validateEmail () {
      if (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(this.managerEmail)) this.isValidataEmail = true
      else this.isValidataEmail = false
    },
    validatePhone () {
      this.managerPhone = this.managerPhone.replace(/[^0-9]/g, '');
    },

    checkUpdateDisable () {
      let retVal = true;
      if(this.managerID !== "" 
      && this.managerName !== ""
      && this.managerPhone !== ""
      && this.managerEmail !== ""
      && this.isValidataEmail
      ) retVal = false;
      return retVal;
    },

    managerUpdate () {
      if(this.managerName === '') {
        this.chgIsAlert({status:true,string:'이름을 입력해 주세요.'})
        return;
      }

      let phoneReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/
      if(this.managerPhone === '' || !phoneReg.test(this.managerPhone)) {
        this.chgIsAlert({status:true,string:'휴대폰번호를 확인해 주세요.'})
        return;
      }

      if(this.managerEmail === '') {
        this.chgIsAlert({status:true,string:'이메일을 입력해 주세요.'})
        return;
      }

      if(!this.isValidataEmail) {
        this.chgIsAlert({status:true,string:'이메일 형식을 확인해 주세요.'})
        return;
      }

      this.managerUpdateAction();
    },
    async managerUpdateAction() {
      let reqObj = {}
      reqObj.target = "/manager/update";
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "alertDetect": this.alertDetect ? 'yes' : 'no',
        "alertOffline": this.alertOffline ? 'yes' : 'no',
        "alertMemo": this.alertMemo ? 'yes' : 'no',
        "isAlert": this.isAlert ? 'yes' : 'no',
        "managerDescription": this.managerDescription,
        "managerEmail": encryptString(this.managerEmail),
        "managerName": encryptString(this.managerName),
        "managerPhone": encryptString(this.managerPhone),
        "managerRole": this.managerRole,
        "textDetect": this.textDetect,
        "textOffline": this.textOffline,
        "managerGuid": this.managerGuid
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'수정 했습니다.'})

        this.$router.push({name:'ManagerList'})
      }
    }
    
  }
}
</script>
<style lang="">
  
</style>